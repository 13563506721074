import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay } from "swiper/modules";
function Testimonials() {
  const didMountRef = useRef(true);
  const [resTestimonialData, setResTestimonialData] = useState([]);
  const [testimonialImagePath, setTestimonialImagePath] = useState([]);
  const [loading , setLoading] = useState()
  const testimonialCarouselOptions = {
    loop: true,
    spaceBetween:15,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView:1,
      },
    },
  };

  useEffect(() => {
    if (didMountRef.current) {
      getTestimonialData();
    }
    didMountRef.current = false;
  }, []);
  const getTestimonialData = () => {
    setLoading(true)
    ApiService.fetchData("featured-testimonial").then((res) => {
      if (res.status == "success") {
        setResTestimonialData(res.resTestimonialData);
        setTestimonialImagePath(res.testimonial_image_path);
        setLoading(false)
      }
    });
  };
  
  return (
    <>
    {resTestimonialData.length > 0 && (

    <section className="testimonial-section sec-pad mb-5">
      <div className="container">
      <div className="row align-items-center justify-content-between">
      <div className="col-lg-5 col-12">
      <div className="section-title mb-3">
        <h6 className="tx-color-02">Testimonials</h6>
       <h2>Happy Clients Say About Lucian International</h2>
       <p>Lucian available, An appreciation is the approval of perfect creativity, meaningful results and committed quality. Lucian deliver stability, better flow of profit and provide high satisfaction to the clients.</p>
     </div>
     <h3>We Have <span className="tx-theme">1356+</span> Global Partners</h3>
      </div>
      <div className="col-lg-6">
      <Swiper {...testimonialCarouselOptions} modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}>
              {resTestimonialData.map((value, index) => {
                return (
                  <SwiperSlide key={index}>
                  <div className="testimonial-box" key={index}>
                    <div className="testimonial-content">
                    <div className="ratting">
                    <img src="/img/google.png" style={{ width: "65px" }} />
                    <br />
                    {[...Array(value.testimonial_rating)].map((_, index) => (
                    <i key={index} className="fas fa-star"></i>
                    ))}
                    </div>
                      <div className="testimonial-des"
                        dangerouslySetInnerHTML={{
                          __html: value.testimonial_desc,
                        }}>
                      </div>
                      <div className="testimonial-details">
                      <div className="testimonial-author">
                      <img
                          src={
                            value.testimonial_image != null
                              ? testimonialImagePath +
                                "/" +
                                value.testimonial_image
                              : constant.DEFAULT_IMAGE
                          }
                          alt="Testimonial"
                        />
                    </div>
                    <div className="designation">
                    <h4>{value.testimonial_name}</h4>
                    <span>{value.testimonial_designation}</span>
                    </div>
                      </div>
                    </div>
               
                   
                  </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
      </div>
      </div>

      </div>

    </section>
   )}
   </>
 );
}
export default Testimonials;
