import React from 'react'

function Collab() {
  return (
    <section className="sec-pad text-center">
    <div className="container">
    <div className="row justify-content-center">
    <div className="col-lg-7">
    <div className="section-title text-center mb-5">
    <p>No time to wait ? Call us ☕️ 🍞</p>
    <h2>Let’s Collaboration With Our Marketing Expert </h2>
    </div>
    < a href="tel:+919314155564" className="btn btn-primary btn-large">Let's Talk to a Specialist</a>
    </div>
    </div>
    </div>
    </section>
  )
}

export default Collab