import React,{useEffect,useRef, useState} from 'react'
import { ApiService } from '../../Components/Services/apiservices';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constant from "../../Components/Services/constant";


function Packages() {
  const didMountRef = useRef(true);

  const [packageData,setPackageData] = useState([])
  const [categoriesData,setCategoriesData] = useState([])

  useEffect(()=>{
    if (didMountRef.current){
      getPackage();
      categorypackage();

    }
    didMountRef.current = false
  
  },[])

  const getPackage= () =>{
    ApiService.fetchData("featured-packages").then((res)=>{
      if (res.status == "success"){
        setPackageData(res.packageData);
        // sessionStorage.setItem("categoryData", JSON.stringify(res.packageData));
        // console.log(res.packageData)
      }
    })

  }
  
  const categorypackage= () =>{

    ApiService.fetchData("category-wise-package").then((res)=>{
      if (res.status == "success"){
        setCategoriesData(res.categoriesData);
        // sessionStorage.setItem("categoryData", JSON.stringify(res.categoriesData));

      }
    })
  }
  const addtocart = (addproduct) => {
    let cartSession = sessionStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    console.log(addproduct);
    const product = {
        product_id: Number(addproduct.package_id),
        product_name: addproduct.package_name,
        product_image: addproduct.product_image
            ? addproduct.product_image
            : constant.DEFAULT_IMAGE,
        product_price: Number(addproduct.package_price),
        product_selling_price: Number(addproduct.package_selling_price),
        product_discount: addproduct.package_discount,
        product_gst_amt: addproduct.package_actual_gst_amt,
        product_variation: [],
    };
  
    
        if (addproduct.product_inventory === 1) {
            if (Number(addproduct.product_stock) > 0) {
                const existingProductIndex = cartSession.findIndex((item) => {
                    return (
                        item.product_id === product.product_id &&
                        JSON.stringify(item.product_variation) === JSON.stringify(product.product_variation)
                    );
                });
  
                if (existingProductIndex !== -1) {
                    if (
                        cartSession[existingProductIndex].quantity + 1 <= Number(addproduct.product_stock) &&
                        (Number(addproduct.product_moq) === 0 ||
                        cartSession[existingProductIndex].quantity + 1 <= Number(addproduct.product_moq))
                    ) {
                        cartSession[existingProductIndex].quantity += 1;
                        toast.success("Quantity updated Successfully");
                    } else {
                        toast.error(
                            "You can add a maximum of " + addproduct.product_moq + " quantity at a time!"
                        );
                        return false;
                    }
                } else {
                    const cartProduct = { ...product, quantity: 1 };
                    cartSession.push(cartProduct);
                    toast.success("Product added to cart Successfully");
                }
            } else {
                toast.error("Product is out of stock");
                return false;
            }
        } else {
            const cartProduct = { ...product, quantity: 1 };
            cartSession.push(cartProduct);
            toast.success("Product added to cart Successfully");
        }

  
    sessionStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
    cartSession = sessionStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    sessionStorage.removeItem("COUPON_SESSION");
};


  
  const buynow = (value) => {

 console.log(value);
 sessionStorage.setItem('buynowValue', JSON.stringify(value));
  }

  return (
<section className="sec-pad">
  <div className="container">
    <div className="section-title text-center mb-5">
      <h2>Featured Products Plans</h2>
    </div>

    {categoriesData.map((value, index) => (
      value.packages && value.packages.length > 0 ? (
      <div className="row g-4 mb-5" key={index}>
        <h2 className='text-center'>{value.cat_name}</h2>
        {value.packages.map((subvalue, indexProduct) => (
          <div className="col-lg-3" key={indexProduct}>
            <div className="pricingtable col1">
              <div className="pricingtable-header">{subvalue.package_name}</div>
              <div className="pricingtable-content">
                <div className="prthed">
                  <div className="prthed-inner">
                  <div className="prtihead">
                  <div className="prtihead-text">
                    {subvalue.package_price == 0 ? 'FREE' : `₹${subvalue.package_price}`}
                  </div>
                  <div className="prtihead-but">
                    {subvalue.package_price == 0 ? 'FREE' : `SAVE ₹${subvalue.package_discount}`}
                  </div>
                  </div>
                  <div className="prthedprice">
                    <div className="prthedprice-inner">
                    <span className="prthedprice-price">
                      {subvalue.package_selling_price == 0 ? 'FREE' : `₹${subvalue.package_selling_price}+18% GST`}
                    </span>
                    <span className="prthedprice-day">/mo</span>
                    </div>
                    </div>

                  </div>
                  <p className="prthed-text">Buy & Renew at the same cost</p>
                  <a href="/cart" className="prthedbutton mt-2" onClick={(e) => addtocart(subvalue)}>BUY NOW</a>
 
                </div>
                <div className='prthedlist' dangerouslySetInnerHTML={{ __html: subvalue.package_description }}>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
   ) : null
   ))}
    
  </div>
</section>

  
  )
}

export default Packages