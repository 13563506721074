import React from 'react'

function Description() {
  return (
<section className="sec-pad">
<div className="container">
<div className="section-title text-center mb-5">
<p>Anyone can make you Promises…</p>
<h2>We Can Give You Proof</h2>
</div>
<div className="row">
<div className="col-xl-3 col-lg-3 col-md-6 col-12">
<div className="funfact d-flex align-items-start mb-30 justify-content-center">
<div className="funfact-icon">
<img  src="https://wp.themepure.net/seomy/wp-content/uploads/2023/09/funfact-icon-1.png" alt=""/>
</div>
<div className="funfact-content">
<h3 className="funfact-count">
<span className="purecounter">15</span>K+</h3>
<p><span>Complete </span> Projects</p>
</div>
</div>
</div>
<div className="col-xl-3 col-lg-3 col-md-6 col-12">
<div className="funfact d-flex align-items-start mb-30 justify-content-center">
<div className="funfact-icon">
<img src="https://wp.themepure.net/seomy/wp-content/uploads/2023/09/funfact-icon-2.png" alt=""/>
</div>
<div className="funfact-content">
<h3 className="funfact-count">
<span className="purecounter">7 years</span>+</h3>
<p><span>Experience</span> in Years</p>
</div>
</div>
</div>
<div className="col-xl-3 col-lg-3 col-md-6 col-12">
<div className="funfact d-flex align-items-start mb-30 justify-content-center">
<div className="funfact-icon">
<img src="https://wp.themepure.net/seomy/wp-content/uploads/2023/09/funfact-icon-3.png" alt=""/>
</div>
<div className="funfact-content">
<h3 className="funfact-count">
<span className="purecounter">600</span>+</h3>
<p><span>Happy</span> Clients</p>
</div>
</div>
</div>
<div className="col-xl-3 col-lg-3 col-md-6 col-12">
<div className="funfact d-flex align-items-start mb-30 justify-content-center">
<div className="funfact-icon">
<img src="https://wp.themepure.net/seomy/wp-content/uploads/2023/09/funfact-icon-1.png" alt=""/>
</div>
<div className="funfact-content">
<h3 className="funfact-count">
<span className="purecounter">35</span>+</h3>
<p><span>Team</span> Strength</p>
</div>
</div>
</div>
</div>
</div>
</section>
  )
}

export default Description