import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { ApiService } from "../Services/apiservices";
import moment from "moment";

function Blogs() {
    const didMountRef = useRef(true);
    const [blogData, setBlogData] = useState([])
    const [blogImagePath, setBlogImagePath] = useState('')

    useEffect(() => {

     if(didMountRef.current){
        getBlogData()
     }
     didMountRef.current = false;
    
    }, [])
    

    const getBlogData = () =>{
        ApiService.fetchData("featured-blogs").then((res) => {
            if (res.status == "success") {
                setBlogData(res.blogsData);
                setBlogImagePath(res.blog_image_path);
 
            }
          });
    } 
  const blogsCarouselOptions = {
    loop: true,
    spaceBetween: 15,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      <section className="sec-pad">
        <div className="container">
          <div className="section-title text-center mb-5">
            <div className="subtitle">Latest Articles</div>
            <h2>Latest News & Blogs</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Swiper
                {...blogsCarouselOptions}
                modules={[Autoplay]}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >

          {blogData.map((value, index) => {
            return (
                <SwiperSlide>
                  <div className="blog-card">
                  <a href={`/news-blogs/${value.blog_slug}`}>
                    <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_name}/>
                   
                    </a>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="ri-calendar-todo-fill"></i>{moment(value.created_at).format('MMM D, YYYY')}
                        </li>
                        <li>
                          <i className="ri-price-tag-3-fill"></i>
                          <a href={`/news-blogs/${value.blog_slug}`}>{value.blog_tag_name}</a>
                        </li>
                      </ul>
                      <h4>
                       <a href={`/news-blogs/${value.blog_slug}`}>
                       {value.blog_name}
                        </a>
                      </h4>
                      <p>
                      {value.blog_short_description}
                      </p>
                      <a href={`/news-blogs/${value.blog_slug}`} className="read-btn">
                        Read More
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
);
      })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs;
