import React,{ useEffect } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import constant from "../../Components/Services/constant";
import { BrowserView, MobileView } from 'react-device-detect';

function ServiceCategory() {
  const didMountRef = useRef(true);
  const { slug } = useParams();
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [serviceCategory, setServiceCategory] = useState([])
  const [childrenCategory, setChildrenCategory] = useState([])
  const [categoryImagePath, setCategoryImagePath] = useState([])
  

    useEffect(()=>{
      if(didMountRef.current){
        getServiceCategory()
        const getPageData = {
          slug: "career",
        };
        ApiService.postData("page-content", getPageData).then((res) => {
          if (res.status == "success") {
            setPageData(res.data);
            setPageContent(res.data.page_content);
          }
        });
      }
      didMountRef.current= false;

    })

 
    const getServiceCategory = () =>{
        const getServiceData = {
          "cat_slug":slug
          
          };
        ApiService.postData("service-category", getServiceData).then((res) => {
            if (res.status == "success") {
              setServiceCategory(res.parentCategory)
              setChildrenCategory(res.parentCategory.children)
              setCategoryImagePath(res.category_image_path);
            }
          });
    }


  return (
    <>  
 
    <Header state="inner-header" />
    <BrowserView>
       <div className='subheader'>
       <Container>
            <Row>
              <Col lg={12}>
              <h1>{serviceCategory.cat_name}</h1>
              <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                  <Breadcrumb.Item active>{serviceCategory.cat_name}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            </Row>
            </Container>
  
        <ul className='shape-group'>
        <li className="shape-1"><span></span></li>
        <li className="shape-2 scene"><img data-depth="2" src="/img/shape-13.png" alt="shape"/></li>
        <li className="shape-3 scene"><img data-depth="2" src="/img/shape-15.png" alt="shape"/></li>
        <li className="shape-4"><span></span></li>
        <li className="shape-5 scene"><img data-depth="2" src="/img/shape-07.png" alt="shape"/></li>
        
        </ul>
       </div>
       <section className='sec-pad'>
       <div className='container'>
          <div className='row g-3'>
          {
             childrenCategory.map((value,index)=>(
            <div className='col-lg-4'>
              <div className='servbox'>
                <div className='servbox-inner'>
                  <div className='servboxthumbnail'>
                  <a href={`/collection/services/${value.cat_slug}`}><img src={value.cat_image != null ? categoryImagePath + "/" + value.cat_image : constant.DEFAULT_IMAGE} alt={value.services_image} style={{width:'100%'}}/></a>
       
                  </div>
                  <div className='servboxcontent'>
                    <h2>{value.cat_name}</h2>
                    <p>{value.cat_desc}</p>
                    <div className="read-more-btn">
                    <a className="btn btn-primary btn-medium" href={`/collection/services/${value.cat_slug}`}>View Details <i className="icon-4"></i></a>
                                    </div>
                              
                  </div>
                </div>
              </div>
            </div>
             ))}
            </div>
            </div>
       </section>
       </BrowserView>
       <MobileView>

       <div className='subheader'>
       <Container>
            <Row>
              <Col lg={12}>
              <h1>{serviceCategory.cat_name}</h1>
              <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                  <Breadcrumb.Item active>{serviceCategory.cat_name}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            </Row>
            </Container>
       </div>
       <section className='sec-pad'>
       <div className='container'>
          <div className='row g-3'>
          {
             childrenCategory.map((value,index)=>(
            <div className='col-lg-4'>
              <div className='servbox'>
                <div className='servbox-inner'>
                  <div className='servboxthumbnail'>
                  <a href={`/collection/services/${value.cat_slug}`}><img src={value.cat_image != null ? categoryImagePath + "/" + value.cat_image : constant.DEFAULT_IMAGE} alt={value.services_image} style={{width:'100%'}}/></a>
       
                  </div>
                  <div className='servboxcontent'>
                    <h2>{value.cat_name}</h2>
                    <p>{value.cat_desc}</p>
                    <div className="read-more-btn">
                    <a className="btn btn-primary btn-medium" href={`/collection/services/${value.cat_slug}`}>View Details <i className="icon-4"></i></a>
                                    </div>
                              
                  </div>
                </div>
              </div>
            </div>
             ))}
            </div>
            </div>
       </section>

       </MobileView>
    <Footer/>
    </>
  )
}

export default ServiceCategory