import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { ApiService } from "../Services/apiservices";


function Services() {

    const didMountRef = useRef(true);

    const [servicesData, setServicesData] = useState([])
    const [servicesImagePath, setServicesImagePath] = useState('')

    useEffect(() => {

     if(didMountRef.current){
        getBlogData()
     }
     didMountRef.current = false;
    }, [])
    
    const getBlogData = () =>{

        ApiService.fetchData("featured-services").then((res) => {
            if (res.status == "success") {
                setServicesData(res.servicesData);
                setServicesImagePath(res.service_image_path);
            }
          });
    } 
  
  const serviceCarouselOptions = {
    loop: true,
    spaceBetween: 15,
    centeredSlides: true,
    
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView: 3.5,
      },
    },
  };

  return (
    <>
      <section className="sec-pad">
        <div className="section-title text-center mb-5">
          <div className="subtitle">Services</div>
          <h2>Our Featured Services</h2>
        </div>

     <Swiper {...serviceCarouselOptions} modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}>
            {servicesData.map((value,index)=>{
                return(

                    <SwiperSlide>
                    <div className="tpcase">
                      <div className="tpcase-thumb">
                        <a href={`/services/${value.services_slug}`}>
                          <img
                            src={value.services_image != null ? servicesImagePath + "/" + value.services_image : constant.DEFAULT_IMAGE}
                            alt={value.services_name}
                            width="570"
                            height="420"
                            loading="lazy"
                          />
                        </a>
                      </div>
                      <div className="tpcase-content">
                        <h3 className="tpcase-title">
                          <a href={`/services/${value.services_slug}`}>{value.services_name}</a>
                        </h3>
                      </div>
                    </div>
                  </SwiperSlide>
                  
                )
            })
            }
        </Swiper>
      </section>
    </>
  );
}

export default Services;
