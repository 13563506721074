import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
import constant from "../../Components/Services/constant";
function HeaderMenu() {
  const didMountRef = useRef(true);
  const [menuData, setMenuData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      menuelist();
    }
    didMountRef.current = false;
  }, []);
  const menuelist = () => {
    ApiService.fetchData("menue-list").then((res) => {
      if (res.status === "success") {
        setMenuData(res.data);
        setImageUrl(res.imageUrl);
      }
    });
  };
  const gotToPage = (route) => {
    navigate(route);
  };
  return (
    <>
      <div className="header-center">
      <nav className="navbar navbar-expand-lg navbar-light">
      <ul className="navbar-nav ms-auto">
    
      {menuData.length > 0
              ? menuData.map((parent, indexParent) => {
                  if (parent.menu_mega === 0) {
                    if (parent.children.length > 0) {
                      if (parent.menu_categoryid > 0) {
                        return (
                          <li
                            className="nav-item dropdown dropdown-hover"
                            key={indexParent}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              href={"/collection/services/" + parent.categories.cat_slug}
                              id="navbarDropdownMenuLinkRight"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {parent.menu_name}
                            </a>
                            <ul
                              className="dropdown-menu submenu"
                              aria-labelledby="navbarDropdownMenuLinkRight"
                            >
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={
                                            "/collection/" +
                                            firstChild.categories.cat_slug
                                          }
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                        
                                      </li>
                                    );
                                  } else if (firstChild.menu_pageid > 0) {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={"/" + firstChild.pages.page_url}
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={firstChild.menu_customlink}
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                      </li>
                                    );
                                  }
                                }
                              )}
                            </ul>
                          </li>
                        );
                      } else if (parent.menu_pageid > 0) {
                        return (
                          <li
                            className="nav-item dropdown dropdown-hover"
                            key={indexParent}
                          >
                            <a
                              className="nav-link "
                              href={"/" + parent.pages.page_url}
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {parent.menu_name}
                            </a>
                            <ul
                              className="dropdown-menu submenu"
                              aria-labelledby="navbarDropdown"
                            >
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={
                                            "/collection/" +
                                            firstChild.categories.cat_slug
                                          }
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                  <ul className="dropdown-menu dropdown-submenu dropdown-submenu-left submenu">
                                    {firstChild.children.map((secondChild, indexSecondChild) => {
                                      if (secondChild.menu_categoryid > 0) {
                                        return (
                                          <li key={indexSecondChild}>
                                          <a className="dropdown-item" href={`/collection/services/${secondChild.categories.cat_slug}`}>
                                              {secondChild.menu_name}
                                            </a>
                                          </li>
                                        );
                                      }else if(secondChild.menu_pageid > 0) {
                                        return(
                                          <li key={indexSecondChild}>
                                          <a
                                            className="dropdown-item"
                                            href={"/" + secondChild.pages.page_url}
                                          >
                                            {firstChild.menu_name}
                                          </a>
                                        </li>
                                        )
                                      } else {
                                        return (
                                          <li key={indexSecondChild}>
                                            <a
                                              className="dropdown-item"
                                              href={secondChild.menu_customlink}
                                            >
                                              {secondChild.menu_name}
                                            </a>
                                          </li>
                                        );
                                      }
                                    })}
                                    </ul>


                                      </li>
                                    );
                                  } else if (firstChild.menu_pageid > 0) {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={"/" + firstChild.pages.page_url}
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={firstChild.menu_customlink}
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                      </li>
                                    );
                                  }
                                }
                              )}
                            </ul>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            className="nav-item dropdown dropdown-hover"
                            key={indexParent}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              href={parent.menu_customlink}
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {parent.menu_name}
                            </a>
                            <ul
                              className="dropdown-menu submenu"
                              aria-labelledby="navbarDropdown"
                            >
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={
                                            "/collection/" +
                                            firstChild.categories.cat_slug
                                          }
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                        <ul className="dropdown-menu dropdown-submenu dropdown-submenu-left submenu">
                                    {firstChild.children.map((secondChild, indexSecondChild) => {
                                      if (secondChild.menu_categoryid > 0) {
                                        return (
                                          <li key={indexSecondChild}>
                                          <a className="dropdown-item" href={`/collection/services/${secondChild.categories.cat_slug}`}>
                                              {secondChild.menu_name}
                                            </a>
                                          </li>
                                        );
                                      }else if(secondChild.menu_pageid > 0) {
                                        return(
                                          <li key={indexSecondChild}>
                                          <a
                                            className="dropdown-item"
                                            href={"/" + secondChild.pages.page_url}
                                          >
                                            {firstChild.menu_name}
                                          </a>
                                        </li>
                                        )
                                      } else {
                                        return (
                                          <li key={indexSecondChild}>
                                            <a
                                              className="dropdown-item"
                                              href={secondChild.menu_customlink}
                                            >
                                              {secondChild.menu_name}
                                            </a>
                                          </li>
                                        );
                                      }
                                    })}
                                    </ul>
                                      </li>
                                    );
                                  } else if (firstChild.menu_pageid > 0) {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={"/" + firstChild.pages.page_url}
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li key={indexFirstChild}>
                                        <a
                                          className="dropdown-item"
                                          href={firstChild.menu_customlink}
                                        >
                                          {firstChild.menu_name}
                                        </a>
                                      </li>
                                    );
                                  }
                                }
                              )}
                            </ul>
                          </li>
                        );
                      }
                    } else {
                      if (parent.menu_categoryid > 0) {
                        return (
                          <li className="nav-item" key={indexParent}>
                            <a
                              className="nav-link"
                              aria-current="page"
                              href={"/collection/services/" + parent.categories.cat_slug}
                            >
                              {parent.menu_name}
                            </a>
                          </li>
                        );
                      } else if (parent.menu_pageid > 0) {
                        return (
                          <li className="nav-item" key={indexParent}>
                            <a
                              className="nav-link"
                              aria-current="page"
                              href={"/" + parent.pages.page_url}
                            >
                              {parent.menu_name}
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li className="nav-item" key={indexParent}>
                            <a
                              className="nav-link"
                              aria-current="page"
                              href={parent.menu_customlink}
                            >
                              {parent.menu_name}
                            </a>
                          </li>
                        );
                      }
                    }
                  } else {
                    if (parent.children.length > 0) {
                      return (
                        <li className="nav-item dropdown dropdown-hover" key={indexParent}>
                          <a
                            className="nav-link dropdown-toggle"
                            href={"/" + parent.pages.page_url}
                            id="navbarDropdownMenuLinkRight"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                              {parent.menu_name}
                          </a>
                          <ul
                            className="dropdown-menu dropdown-submenu dropdown-submenu-left submenu"
                            aria-labelledby="navbarDropdownMenuLinkRight"
                          >
                            {parent.children.map((secondChild, indexSecondChild) => {
                          
                              if (secondChild.menu_categoryid > 0) {
                                return (
                                  <li key={indexSecondChild}>
                                    <a
                                      className="dropdown-item"
                                      href={
                                        "/collection/services/" +
                                        secondChild.categories.cat_slug
                                      }
                                    >
                                      {secondChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (secondChild.menu_pageid > 0) {
                                return (
                                  <li key={indexSecondChild}>
                                    <a
                                      className="dropdown-item"
                                      href={"/" + secondChild.pages.page_url}
                                    >
                                      {secondChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={indexSecondChild}>
                                    <a
                                      className="dropdown-item"
                                      href={secondChild.menu_customlink}
                                    >
                                      {secondChild.menu_name}
                                    </a>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </li>
                      );
                    } else {
                      
                    }
                  }
                })
              : null}


     
    </ul>
</nav>
      </div>
    </>
  );
}
export default HeaderMenu;