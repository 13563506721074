import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Alert from "react-bootstrap/Alert";

function Enquiry() {
    const didMountRef = useRef(true);
    const [successMessage, setSuccessMessage] =  useState()
    const [errorMessage, setErrorMessage] =  useState()
    const [contactDetails, setContactDetails] = useState({
        contact_name: "",
        contact_email: "",
        contact_mobile:"",
        contact_subject: "",
        contact_message: "",
      });
    useEffect(() => {
   if(didMountRef.current){
   

   }
   didMountRef.current = false;
    }, [])
    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
    const resetContactForm = () => {
        setContactDetails({
          contact_name: "",
          contact_email: "",
          contact_mobile:"",
          contact_subject: "",
          contact_message: "",
        });
      };
    const contactusProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
          if (myElements[i].value === "") {
            myElements[i].style.border = "1px solid red";
            counter++;
          } else {
            myElements[i].style.border = "";
          }
        }
        if (counter === 0) {
          setErrorMessage("");
          if (!validEmail.test(contactDetails.contact_email)) {
            setErrorMessage("Please enter valid Email Id");
            return false;
          }   
          else if (!validNumber.test(contactDetails.contact_mobile)) {
            setErrorMessage("Please enter valid Number");
            return false;
          }
          ApiService.postData("contact-us-process", contactDetails).then((res) => {
            if (res.status === "success") {
              setSuccessMessage(res.message);
              resetContactForm();
              setTimeout(() => {
                setSuccessMessage("");
              }, 2000);
            } else {
              setErrorMessage(res.message);
              setTimeout(() => {
                setErrorMessage("");
              }, 2000);
            }
          });
        }
      };
      

  return (
     <>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
    <div className="row mb-4 g-3">
        <div className="col-lg-6">
            <div className="form-group-dark"><label>Your Name</label>
            <input
                      type="text"
                      name="contact_name"
                      className="form-control required"
                      value={contactDetails.contact_name}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Your Name"
                    />
            </div>

        </div>

        <div className="col-lg-6">
            <div className="form-group-dark"><label>Mobile Number</label>
            <input
                      type="number"
                      name="contact_mobile"
                      className="form-control required"
                      value={contactDetails.contact_mobile}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Your Number"
                    />
            </div>
        </div>

        <div className="col-lg-12">
            <div className="form-group-dark"><label>Email Address</label>
            <input
                      type="text"
                      name="contact_email"
                      className="form-control required"
                      value={contactDetails.contact_email}
                      onChange={(e) => onTodoChange(e)}
                      placeholder="Your Email"
                    />
            </div>
        </div>
        
        <div className="col-lg-6"><button type="button" className="btn btn-primary bg-light btn-medium text-black" onClick={contactusProcess}>Submit</button></div>
    </div>

    </>
  )
}

export default Enquiry