import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../Components/Elements/mobile_header";
import Alert from "react-bootstrap/Alert";
import sessionCartData from "../../Components/Elements/cart_session_data";

function Documents() {
  const didMountRef = useRef(true);
  const [rowUserData, setRowUserData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [Navshow, setNavShow] = useState(false);
  const handleNavClose = () => setNavShow(false);
  const handleNavShow = () => setNavShow(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentData, setDocumentData] = useState({ documentType :"", inputDocument:"", doc_file:"" })
  const [filePreview, setFilePreview] = useState(null);
  const [resUserDocument, setResUserDocument] = useState([]);
  const [docImagePath, setDocImagePath] = useState('');


  console.log(Navshow);
  useEffect(() => {
    if (didMountRef.current) {
      getuserData();
      getDocumentDetails();
    }
    didMountRef.current = false;
  }, []);

  const getuserData = () => {
    ApiService.fetchData("get-user-data").then((res) => {
      if (res.status == "success") {
        setRowUserData(res.rowUserData);
        setSpinnerLoading(false);
      } else {
        sessionStorage.removeItem("USER_TOKEN");
        setSpinnerLoading(false);
        navigate("/");
      }
    });
  };

   const getDocumentDetails = () =>{
    ApiService.fetchData("get-user-document").then((res) => {
      if (res.status == "success"){
        setResUserDocument(res.resUserDocument);
        setDocImagePath(res.filepath);
        setSpinnerLoading(false);
      }
 })}

 const deleteaddress = (value) => {
  if (window.confirm("Are you sure?")) {
    const dataString = {
      addrid: value,
    };
    ApiService.postData("remove-document", dataString).then((res) => {
      if (res.status == "success") {
        getDocumentDetails();
      }
    });
  } else {
  }
};

  const documentProcess = () =>{
    if (documentData.documentType === "") {
      setErrorMessage('Please Select Document Type');
      return false;
    } else if (documentData.inputDocument === "") {
      setErrorMessage('Please Enter Document Name');
      return false;
    } else if (documentData.doc_file === "") {
      setErrorMessage('Please Upload a Document');
      return false;
    }
    
    const formData = new FormData();
  formData.append("doc_type", documentData.documentType);
  formData.append("doc_details", documentData.inputDocument);
  formData.append("doc_file", documentData.doc_file);
  
      ApiService.postData("document-process", formData).then((res) => {
        if (res.status == "success") {
          setSuccessMessage(res.message);
          window.location.reload();
        }
      });
    };
  
    const handlechangeData = (e) => {
      const { name, value } = e.target; 
      if (name === "documentType") {
        setSelectedOption(value);
      } else if (name === "inputDocument") {
        setInputValue(value);
      }
      setDocumentData({ ...documentData, [name]: value }); 
    };
    
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
      setDocumentData({ ...documentData, doc_file: file }); 
    };

  return (
      <>
         {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
      <Header state="inner-header" cartCount={parsedCartSession.length}/>
        <main className="main">
          <div className="subheader">
            <Container>
              <Row>
                <Col lg={12}>
                  <Breadcrumb className="breadcrumb-inner">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Documents</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="page-content mt-4 mb-4">
            <section>
            <Container>
  <Row>
    <AccountSidebar rowUserData={rowUserData} />
    <Col lg={9}>
      <div className="acpanel">
        <div className="acpanel-header">
          <h4>My Documents</h4>
        </div>
        <div className="acpanel-body">
          {resUserDocument.length > 0 ? (
                <>
            <div className="row">
              {resUserDocument.map((value, index) => (
                <div className="col-lg-6" key={index}>
                  <div className="docaddressbox mb-2">
                    <div className="docaddressbox-body">
                      <h6 className="mb-1 tx-13">
                        Document Type: {value.doc_type}
                      </h6>
                      {value.doc_file &&
                      (value.doc_file.endsWith('.jpg') ||
                        value.doc_file.endsWith('.png')) ? (
                        <img
                          src={
                            value.doc_file != null
                              ? docImagePath + '/' + value.doc_file
                              : ''
                          }
                          alt={value.doc_file}
                          className="mb-1"
                          style={{ maxWidth: '120px', height: '120px' }}
                        />
                      ) : (
                        <p className="mb-1 tx-13">
                          File: {value.doc_file}
                        </p>
                      )}

                      <p className="mb-0 tx-13">
                        Document Details: {value.doc_details}
                      </p>
                    </div>
                    <div className="docaddressbox-footer">
                      {/* <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavShow();
                        }}
                      >
                        Add
                      </a> */}
                      <a
                        href="#"
                        onClick={(e) => deleteaddress(value.doc_id)}
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

                  <Button
                  className="btn btn-primary-outline btn-medium mt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavShow();
                  }}
                >
                  Add New Document
                </Button>
                </>
          ) : (
            <div className="noimg text-center">
              <img
                src="/img/no-orders.webp"
                style={{ width: '250px' }}
                className="mb-3"
              />
              <h6>You have not uploaded any Documents.</h6>
              <Button
                className="btn btn-primary-outline btn-medium btn01"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavShow();
                }}
              >
                Upload
              </Button>
            </div>
          )}
        </div>
      </div>
    </Col>
  </Row>
</Container>

            </section>
          </div>
        </main>
        <Footer />
      </BrowserView>
      <MobileView>
      <MobileHeader PageName="My Documents" Route="account/account-overview" cartCount={parsedCartSession.length}/>
      
      {spinnerLoading === false?
        <main className="main">
       
        {resUserDocument.length > 0 ? (
          resUserDocument.map((value, index) => (
            <div className="maddressbox mb-2">
                 <div className="maddressbox-body">
                      <h6 className="mb-1 tx-13">
                        Document Type: {value.doc_type}
                      </h6>
                      {value.doc_file &&
                      (value.doc_file.endsWith('.jpg') ||
                        value.doc_file.endsWith('.png')) ? (
                        <img
                          src={
                            value.doc_file != null
                              ? docImagePath + '/' + value.doc_file
                              : ''
                          }
                          alt={value.doc_file}
                          className="mb-1"
                          style={{ maxWidth: '120px', height: '120px' }}
                        />
                      ) : (
                        <p className="mb-1 tx-13">
                          File: {value.doc_file}
                        </p>
                      )}

                      <p className="mb-0 tx-13">
                        Document Details: {value.doc_details}
                      </p>
                    </div>
                    <div className="maddressbox-footer">

                      <a
                        href="#"
                        onClick={(e) => deleteaddress(value.doc_id)}
                      >
                        Delete
                      </a>
                    </div>
            </div>
          ))
        ) : (
          <div className="noimg text-center">
              <img
                src="/img/no-orders.webp"
                style={{ width: '250px' }}
                className="mb-3"
              />
              <h6>You have not uploaded any Documents.</h6>
              <Button
                className="btn btn-primary-outline btn-medium btn01"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavShow();
                }}
              >
                Upload
              </Button>
            </div>
        )}
             {resUserDocument.length > 0 ? (
          <div className="maddress-footer">
            <Button
              className="btn btn-primary-outline btn-medium btn-block"
              onClick={(e) => {
                e.preventDefault();
                handleNavShow();
              }}
            >
              Add New Document
            </Button>
          </div>
        ) : (
          ""
        )}
 
      </main>:null}
      
      
      </MobileView>
      {Navshow ? 
      <Modal show={Navshow} onHide={handleNavClose} className="addressModal">
      <button onClick={handleNavClose} className="pop-close"></button>
      <Modal.Body>
        <div className="mb-3 addressModal-header">
          <h5 className="tx-theme mb-1">Add New Document</h5>
        </div>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
        <Form.Group >
          <Form.Label>Select Document Type</Form.Label>
          <Form.Select
            name="documentType"
            value={selectedOption}
            onChange={handlechangeData}
          >
            <option value="">Select Document</option>
            <option value="Gst">Gst Certificate</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>
        {selectedOption && (
          <Form.Group >
            <div className="mt-4">
              <Form.Control
                name="inputDocument"
                type="text"
                placeholder="Enter document details"
                value={inputValue}
                onChange={handlechangeData}
              />
            </div>
          </Form.Group>
        )}
        <Form.Group controlId="fileUpload">
          <div className="mt-4">
            <Form.Control
              type="file"
              accept=".pdf,.doc,.docx,.jpg,.png"
              onChange={handleFileChange}
            />
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary btn-block btn-medium"
          onClick={documentProcess}
        >
          Upload
        </Button>
      </Modal.Footer>

    </Modal>
    : null} 


      </>
  )
}

export default Documents