import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Helmet } from "react-helmet";
import moment from "moment";
import { BrowserView, MobileView } from "react-device-detect";
function Blog(){
    const { slug } = useParams();
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({});
    const [pageContent, setPageContent] = useState("");
    const [blogData, setBlogData] = useState([]);
    const [blogImagePath, setBlogImagePath] = useState([]);
    useEffect(() => {
            if (didMountRef.current) {
              getBlogData();
    const getPageData = {
        slug: "news-blogs",
      };
    ApiService.postData("page-content", getPageData).then(
        (res) => {
            if (res.status == "success") {              
                setPageData(res.data);
             
            }
    })}

 


    didMountRef.current = false;
  });

  const getBlogData = () => {
    ApiService.fetchData("blog-list").then((res) => {
      if (res.status == "success") {
        setBlogData(res.blogsData);
        setBlogImagePath(res.blog_image_path);
      }
    });
  };
    return (     
        <>
         <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :""} />
        {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:""}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
             <meta property="og:image" content= {constant.FRONT_URL+'img/logo.png'}/>
        <meta property="og:url" content={window.location.href} />
        {pageData.page_meta_desc != null ?<meta property="og:description" content={pageData.page_meta_desc} />:""}
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData.page_meta_desc != null ?<meta name="twitter:description" content={pageData.page_meta_desc} />:""}
            <meta property="twitter:image" content= {constant.FRONT_URL+'img/logo.png'}/>
        
      </Helmet>
        <Header state="inner-header" />
        <BrowserView>
        
        <div className="subheader">
          <Container>
            <Row>
           
              <Col lg={12}>
                <h1>{pageData.page_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                 
                  <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                </Breadcrumb>
             
            
              </Col>
            </Row>
          </Container>
          <ul className='shape-group'>
        <li className="shape-1"><span></span></li>
        <li className="shape-2 scene"><img data-depth="2" src="/img/shape-13.png" alt="shape"/></li>
        <li className="shape-3 scene"><img data-depth="2" src="/img/shape-15.png" alt="shape"/></li>
        <li className="shape-4"><span></span></li>
        <li className="shape-5 scene"><img data-depth="2" src="/img/shape-07.png" alt="shape"/></li>
        
        </ul>
        </div>

        <section className="sec-pad">
        <Container>
        <Row>
        {blogData.map((value,index)=>( 
        <Col lg={4} key={index}>
         <div className="blog-card">
                  <a href={`/news-blogs/${value.blog_slug}`}>
                    <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_name}/>
                   
                    </a>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="ri-calendar-todo-fill"></i>{moment(value.created_at).format('MMM D, YYYY')}
                        </li>
                        <li>
                          <i className="ri-price-tag-3-fill"></i>
                          <a href={`/news-blogs/${value.blog_slug}`}>{value.blog_tag_name}</a>
                        </li>
                      </ul>
                      <h4>
                       <a href={`/news-blogs/${value.blog_slug}`}>
                       {value.blog_name}
                        </a>
                      </h4>
                      <p>
                      {value.blog_short_description}
                      </p>
                      <a href={`/news-blogs/${value.blog_slug}`} className="read-btn">
                        Read More
                      </a>
                    </div>
                  </div>
       </Col>
 ))} 
      
            </Row>
          </Container>
        </section>
        </BrowserView>
        <MobileView>

        <div className="subheader">
          <Container>
            <Row>
           
              <Col lg={12}>
                <h1>{pageData.page_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                 
                  <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                </Breadcrumb>
             
            
              </Col>
            </Row>
          </Container>
   
        </div>

        <section className="sec-pad">
        <Container>
        <Row>
        {blogData.map((value,index)=>( 
        <Col lg={4} key={index}>
         <div className="blog-card">
                  <a href={`/news-blogs/${value.blog_slug}`}>
                    <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_name}/>
                   
                    </a>
                    <div className="content">
                      <ul>
                        <li>
                          <i className="ri-calendar-todo-fill"></i>{moment(value.created_at).format('MMM D, YYYY')}
                        </li>
                        <li>
                          <i className="ri-price-tag-3-fill"></i>
                          <a href={`/news-blogs/${value.blog_slug}`}>{value.blog_tag_name}</a>
                        </li>
                      </ul>
                      <h4>
                       <a href={`/news-blogs/${value.blog_slug}`}>
                       {value.blog_name}
                        </a>
                      </h4>
                      <p>
                      {value.blog_short_description}
                      </p>
                      <a href={`/news-blogs/${value.blog_slug}`} className="read-btn">
                        Read More
                      </a>
                    </div>
                  </div>
       </Col>
 ))} 
      
            </Row>
          </Container>
        </section>
        </MobileView>
        <Footer/>
        </>
    )
}
export default Blog
