import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Container from "react-bootstrap/Container";
import { Col, FormLabel, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BrowserView, MobileView } from "react-device-detect";
function About() {
     const { slug } = useParams();
     const didMountRef = useRef('true')
     const [pageData, setPageData] = useState('')
     const [pageContent, setPageContent] = useState('')
    useEffect(() => {
        if (didMountRef.current) {
         
          const getPageData = {
            slug: "about-us",
          };

          ApiService.postData("page-content", getPageData).then((res) => {
            if (res.status == "success") {
				setPageData(res.data);
				setPageContent(res.data.page_content);
            }
          });
        }
        didMountRef.current = false;
      }, []);

  return (
<>
<BrowserView>

<Header/>
<div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
            <h1>{pageData.page_name}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
        <ul className='shape-group'>
        <li className="shape-1"><span></span></li>
        <li className="shape-2 scene"><img data-depth="2" src="/img/shape-13.png" alt="shape"/></li>
        <li className="shape-3 scene"><img data-depth="2" src="/img/shape-15.png" alt="shape"/></li>
        <li className="shape-4"><span></span></li>
        <li className="shape-5 scene"><img data-depth="2" src="/img/shape-07.png" alt="shape"/></li>
        
        </ul>
      </div>
<section className="sec-pad new-tabs">
			<div className="container">
            <div className="row justify-content-center mb-5">
			<div className="col-lg-4">
<div className="about-detail2"><img alt="" className="w-100 mb-2" src="/img/Satyanarayan.jpg" />
<div className="text-center">
<h5>Mr. Satyanarayan Saini</h5>

<h6>M.D.</h6>

<p>"You must be very patience and very persistent. The world is not going to shower gold coins on you just because you have a good idea. You're going to have to work like crazy to bring that idea to the attention of people. They're not going to buy it unless they know about it".</p>
</div>
</div>
</div>
<div className="col-lg-4">
<div className="about-detail">
<img alt="" className="w-100 mb-2" src="/img/Lalchand.jpg" />
<div className="text-center">
<h5>Mr. Lalchand Kumawat</h5>

<h6>C.E.O.</h6>

<p>"In 2022, more people researched about "How to start a business than How to get a Job".This is not just the era of the entrepreneur - &quot;this is the start of the new era where being an entreprenuer is peoples first choice for a Career".</p>
</div>
</div>
</div>

</div>
				<div className="row mt-5">
					<div className="col-lg-12">
						<div className="new-tabs-innr">
							<ul className="tab-titles">
								<li className="nav-item active"><a href="#tab1" className="nav-link">Our Core Values</a></li>
								{/* <li className="nav-item"><a href="#tab2" className="nav-link">Innovated</a></li> */}
								<li className="nav-item"><a href="#tab3" className="nav-link">Our Vision</a></li>
								<li className="nav-item"><a href="#tab4" className="nav-link">Our Support</a></li>
								<li className="nav-item"><a href="#tab5" className="nav-link">Why us</a></li>
							</ul>
							
							
							<div id="tab1" className="tab-content" style={{display: "block"}}>
								<div className="taglist">
									<p>Our thing is to enhance our customer’s online presence by constantly upgrading our technology and
										capabilities. Maintain a decent relationship with our guests and give services to expand their
										reach to new requests for
										a better expansion of the business. give impeccable client service with the loftiest position of
										moxie and advice.</p>
									<p>At Lucian, we formulate strategies for our guests to make the stylish route to drive business
										for their brand. As the Branding colonist – Johna Sachs said, “ Your Brand is a story unfolding
										across all client touch points. ”,
										at Lucian we work on making the ordinary extraordinary.</p>
								</div>
							</div>
							<div id="tab3" className="tab-content">
								<p>Our vision is to take advantages from our means and chops and understanding of the business
									sphere area to fulfill the conditions of guests to produce a result for their individual
									business requirements. To help guests in creating a positive and strong relationship with their
									followership in brand structure. To be known for our unique approaches and quality work towards
									furnishing the loftiest satisfaction to
									every customer we work with.</p>
								<p>Our conception is to delivered excellent services to our guests with a total commitment towards
									work and getting the job done in the stylish possible way. Honesty and collective trust are
									significant to foster connections with our guests. translucency in what we deliver and to give a
									substantiated and customized services to the guests.</p>
							</div>
							<div id="tab4" className="tab-content">
								<p>Our Smart, creative, and educated platoon is devoted to helping you 24 * 7 by maintaining a
									decent relationship and furnishing you stylish support regarding all your queries to gauge your
									business by delivering innovative digital
									marketing results.</p>
							</div>
							<div id="tab5" className="tab-content">
								<p>Our History was started in 2015 and constantly we’re going stylish on the way of invention and
									creativity. We’ll help you to prepare for online business by furnishing the leading services and
									revamp your place in the online request at a sweet cost.</p>
							</div>
						</div>
					</div>
				</div>

                {pageContent != null ? (
          <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
        ) : (
          ""
        )}

			</div>
		</section>

<Footer/>
</BrowserView>




<MobileView>
	<Header/>
<div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
			<h1>{pageData && pageData.page_name}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>

	  <section className="sec-pad new-tabs">
			<div className="container">
            <div className="row justify-content-center mb-5">
			<div className="col-lg-4">
<div className="about-detail2"><img alt="" className="w-100 mb-2" src="/img/Satyanarayan.jpg" />
<div className="text-center">
<h5>Mr. Satyanarayan Saini</h5>

<h6>M.D.</h6>

<p>"You must be very patience and very persistent. The world is not going to shower gold coins on you just because you have a good idea. You're going to have to work like crazy to bring that idea to the attention of people. They're not going to buy it unless they know about it".</p>
</div>
</div>
</div>
<div className="col-lg-4">
<div className="about-detail">
<img alt="" className="w-100 mb-2" src="/img/Lalchand.jpg" />
<div className="text-center">
<h5>Mr. Lalchand Kumawat</h5>

<h6>C.E.O.</h6>

<p>"In 2022, more people researched about "How to start a business than How to get a Job".This is not just the era of the entrepreneur - &quot;this is the start of the new era where being an entreprenuer is peoples first choice for a Career".</p>
</div>
</div>
</div>

</div>
				<div className="row mt-5">
					<div className="col-lg-12">
						<div className="new-tabs-innr">
							<ul className="tab-titles">
								<li className="nav-item active"><a href="#tab1" className="nav-link">Our Core Values</a></li>
								{/* <li className="nav-item"><a href="#tab2" className="nav-link">Innovated</a></li> */}
								<li className="nav-item"><a href="#tab3" className="nav-link">Our Vision</a></li>
								<li className="nav-item"><a href="#tab4" className="nav-link">Our Support</a></li>
								<li className="nav-item"><a href="#tab5" className="nav-link">Why us</a></li>
							</ul>
							
							
							<div id="tab1" className="tab-content" style={{display: "block"}}>
								<div className="taglist">
									<p>Our thing is to enhance our customer’s online presence by constantly upgrading our technology and
										capabilities. Maintain a decent relationship with our guests and give services to expand their
										reach to new requests for
										a better expansion of the business. give impeccable client service with the loftiest position of
										moxie and advice.</p>
									<p>At Lucian, we formulate strategies for our guests to make the stylish route to drive business
										for their brand. As the Branding colonist – Johna Sachs said, “ Your Brand is a story unfolding
										across all client touch points. ”,
										at Lucian we work on making the ordinary extraordinary.</p>
								</div>
							</div>
							<div id="tab3" className="tab-content">
								<p>Our vision is to take advantages from our means and chops and understanding of the business
									sphere area to fulfill the conditions of guests to produce a result for their individual
									business requirements. To help guests in creating a positive and strong relationship with their
									followership in brand structure. To be known for our unique approaches and quality work towards
									furnishing the loftiest satisfaction to
									every customer we work with.</p>
								<p>Our conception is to delivered excellent services to our guests with a total commitment towards
									work and getting the job done in the stylish possible way. Honesty and collective trust are
									significant to foster connections with our guests. translucency in what we deliver and to give a
									substantiated and customized services to the guests.</p>
							</div>
							<div id="tab4" className="tab-content">
								<p>Our Smart, creative, and educated platoon is devoted to helping you 24 * 7 by maintaining a
									decent relationship and furnishing you stylish support regarding all your queries to gauge your
									business by delivering innovative digital
									marketing results.</p>
							</div>
							<div id="tab5" className="tab-content">
								<p>Our History was started in 2015 and constantly we’re going stylish on the way of invention and
									creativity. We’ll help you to prepare for online business by furnishing the leading services and
									revamp your place in the online request at a sweet cost.</p>
							</div>
						</div>
					</div>
				</div>

                {pageContent != null ? (
          <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
        ) : (
          ""
        )}

			</div>
		</section>

<Footer/>

</MobileView>

</>
    






  )
}

export default About