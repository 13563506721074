import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import constant from "../../Components/Services/constant";

function ServicesDetail() {
  const productCarouselOptions = {
    loop: true,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 2.2,
      },
      600: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 4,
      },
    },
  };
  const [serviceDetailData, setServiceDetailData] = useState({});
  const [serviceImageUrl, setServiceImageUrl] = useState("");
  const [categoriesName, setCategoriesName] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);

 const { slug } = useParams();
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getServiceData();
    }

    didMountRef.current = false;
  });

  const getServiceData = () => {
    const getServiceDetail = {
      service_slug: slug,
    };
    ApiService.postData("service-detail", getServiceDetail).then((res) => {
      if (res.status == "success") {
        setServiceDetailData(res.servicesData);
        setServiceImageUrl(res.service_image_path);
        setCategoriesName(res.categoriesData);
        setRelatedProducts(res.relprodData);

      }
    });
  };
  
  return (
    <>
      <Header />
      <BrowserView>
      <div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>{serviceDetailData.services_name}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                <Breadcrumb.Item active>
                  {serviceDetailData.services_name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
        <ul className="shape-group">
          <li className="shape-1">
            <span></span>
          </li>
          <li className="shape-2 scene">
            <img data-depth="2" src="/img/shape-13.png" alt="shape" />
          </li>
          <li className="shape-3 scene">
            <img data-depth="2" src="/img/shape-15.png" alt="shape" />
          </li>
          <li className="shape-4">
            <span></span>
          </li>
          <li className="shape-5 scene">
            <img data-depth="2" src="/img/shape-07.png" alt="shape" />
          </li>
        </ul>
      </div>
      <section className="sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div>
                {serviceDetailData.services_image != null ? (
                  <img
                    src={
                      serviceDetailData.services_image != null
                        ? serviceImageUrl +
                          "/" +
                          serviceDetailData.services_image
                        : ""
                    }
                    alt={serviceDetailData.services_image}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <p
                dangerouslySetInnerHTML={{
                  __html: serviceDetailData.services_desc,
                }}
              ></p>
            </div>
          </div>
          <div className="spacer1 mt-5"></div>
            <div className="p-3">
              <h3 className="text-center mt-5">Related Products</h3>
              <Swiper
  {...productCarouselOptions}
>
  {relatedProducts.map((value, index) => (
    <SwiperSlide key={index}>
                      <div className="product2 mt-4" >
                        <figure className="product-media2">
                          <a href="#">
                            <img
                            src= {value.services_image != null? serviceImageUrl + "/" + value.services_image : constant.DEFAULT_IMAGE}alt={value.services_image}
                            />
                          </a>
                   
                        </figure>
                        <div className="product_detail2 border-bottom" >
                        <a href="#">
                          <p style={{color:"#000"}}>{value.services_name}</p>

                        </a>
                        </div>
                    
                      </div>
                      </SwiperSlide>
  ))}
</Swiper>
            </div>
        </div>
      </section>
</BrowserView>
<MobileView>
      <div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>{serviceDetailData.services_name}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                <Breadcrumb.Item active>
                  {serviceDetailData.services_name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
  
      </div>
      <section className="sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div>
                {serviceDetailData.services_image != null ? (
                  <img
                  className="w-100"
                    src={
                      serviceDetailData.services_image != null
                        ? serviceImageUrl +
                          "/" +
                          serviceDetailData.services_image
                        : ""
                    }
                    alt={serviceDetailData.services_image}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <p
                dangerouslySetInnerHTML={{
                  __html: serviceDetailData.services_desc,
                }}
              ></p>
            </div>
          </div>
          <div className="spacer1"></div>
            <div className="p-3">
              <h3 className="text-center mt-5">Related Products</h3>
              <Swiper
  {...productCarouselOptions}
>
  {relatedProducts.map((value, index) => (
    <SwiperSlide key={index}>
                      <div className="product2 mt-4" >
                        <figure className="product-media2">
                          <a href="#">
                            <img
                            src= {value.services_image != null? serviceImageUrl + "/" + value.services_image : constant.DEFAULT_IMAGE}alt={value.services_image}
                            />
                          </a>
                   
                        </figure>
                        <div className="product_detail2 border-bottom" >
                        <a href="#">
                          <p style={{color:"#000"}}>{value.services_name}</p>

                        </a>
                        </div>
                    
                      </div>
                      </SwiperSlide>
  ))}
</Swiper>
            </div>
        </div>
      </section>
</MobileView>

      <Footer />
    </>
  );
}

export default ServicesDetail;
