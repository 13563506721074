import React, { useEffect, useState, useRef } from "react";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { useNavigate, useParams } from "react-router-dom";
import MobileHeader from "../../Components/Elements/mobile_header";
import Alert from "react-bootstrap/Alert";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Button } from "react-bootstrap";
import moment from "moment";

function Reports() {
  const didMountRef = useRef(true);
  const [rowUserData, setRowUserData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const dataArray = sessionCartData();
  const [reportData, setReportData] = useState([]);
  const [reportPdfPath, setReportPdfPath] = useState("");

  const parsedCartSession = dataArray[1];
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
  
      getuserData()
      getReportData()
    }
    didMountRef.current = false;
  }, []);

  const getuserData = () => {
    ApiService.fetchData("get-user-data").then((res) => {
      if (res.status == "success") {
        setRowUserData(res.rowUserData);
        setSpinnerLoading(false);
      } else {
        sessionStorage.removeItem("USER_TOKEN");
        setSpinnerLoading(false);
        navigate("/");
      }
    });
  };
  const getReportData = () => {
  
    ApiService.postData("customer-report-listing").then((res) => {
      if (res.status == "success") {
        setReportData(res.reportdata);
        setReportPdfPath(res.reportPath);
        setSpinnerLoading(false);
      }
    });
  };

  return (

      <>
        {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
      <Header state="inner-header" cartCount={parsedCartSession.length}/>
        <main className="main">
          <div className="subheader">
            <Container>
              <Row>
                <Col lg={12}>
                  <Breadcrumb className="breadcrumb-inner">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="page-content mt-4 mb-4">
            <section>
              <Container>
                <Row>
                  <AccountSidebar rowUserData={rowUserData}/>
                  <Col lg={9}>

                    <div className="acpanel">
                      <div className="acpanel-header">
                        <h4>My Reports</h4>
                      </div>
                      <div className="acpanel-body d-flex">
                      {reportData.map((value,index)=>( 
                        <div className="pe-3">
                   <a href={`${reportPdfPath}/${value.csrep_file}`} download={value.csrep_file} target="_blank">
                      <Button variant="primary" >
          Download Report
        </Button>
        </a>
        <p>{moment(value.created_at).format('MMM D, YYYY')}</p>

                        </div>
 ))} 
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </main>
        <Footer />
      </BrowserView>
      <MobileView>
      <MobileHeader PageName="My Reports" Route="account/account-overview" cartCount={parsedCartSession.length}/>
      <div className="p-3">
            <div className="row">
              <div className="col-lg-12">
              {reportData.map((value,index)=>( 
                        <div>
                   <a href={`${reportPdfPath}/${value.csrep_file}`} download={value.csrep_file} target="_blank">
                      <Button variant="primary" >
          Download Report
        </Button>
        </a>
        <p>{moment(value.created_at).format('MMM D, YYYY')}</p>

                        </div>
 ))} 
                      </div>
            </div>
          </div>
      </MobileView>
      </>
  )
}

export default Reports