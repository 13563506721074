// const API_URL = "https://studykee.com/2023/lucianinternational/csadmin/api/"
const API_URL = "https://lucianinternational.com/csadmin/api/"



const BASE_URL =   "https://lucianinternational.com/"    
const FRONT_URL =   "https://lucianinternational.com/"    
const DEFAULT_IMAGE = "/img/defaultimage.png";
const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting, BASE_URL,FRONT_URL,DEFAULT_IMAGE };