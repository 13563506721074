import React, { useEffect, useRef, useState } from "react";
import { ApiService } from '../../Components/Services/apiservices';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BrowserView, MobileView } from "react-device-detect";

function FAQs() {
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({});
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        if (didMountRef.current) {
            getPageData();
            getFaqData();
        }
        didMountRef.current = false;
    }, []);

    const getPageData = () => {
        const pageData = {
            slug: "faqs",
        };
        ApiService.postData("page-content", pageData).then((res) => {
            if (res.status === "success") {
                setPageData(res.data);
            }
        });
    };

    const getFaqData = () => {
        ApiService.fetchData("faqs").then((res) => {
            if (res.status === "success") {
                setFaqData(res.resFaqs);
            }
        });
    };

    return (
        <>
            <Header />
            <BrowserView>
            <div className="subheader">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1>{pageData.page_name}</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
                <ul className='shape-group'>
        <li className="shape-1"><span></span></li>
        <li className="shape-2 scene"><img data-depth="2" src="/img/shape-13.png" alt="shape"/></li>
        <li className="shape-3 scene"><img data-depth="2" src="/img/shape-15.png" alt="shape"/></li>
        <li className="shape-4"><span></span></li>
        <li className="shape-5 scene"><img data-depth="2" src="/img/shape-07.png" alt="shape"/></li>
        
        </ul>
            </div>

            <section className="sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='mb-5'>
                                <Accordion>
                                    {faqData.map((value, index) => {
                                        return (
                                            <Accordion.Item key={index} eventKey={index} className="checkout-accord">
                                                <Accordion.Header>
                                                    <h6 className="mb-0 tx-14">{value.faq_title}</h6>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="order-notetextarea">
                                                     <p dangerouslySetInnerHTML={{ __html: value.faq_description }}></p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </BrowserView>
            <MobileView>
            <div className="subheader">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1>{pageData.page_name}</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>

            </div>

            <section className="sec-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='mb-5'>
                                <Accordion>
                                    {faqData.map((value, index) => {
                                        return (
                                            <Accordion.Item key={index} eventKey={index} className="checkout-accord">
                                                <Accordion.Header>
                                                    <h6 className="mb-0 tx-14">{value.faq_title}</h6>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="order-notetextarea">
                                                     <p dangerouslySetInnerHTML={{ __html: value.faq_description }}></p>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            </MobileView>
      

            <Footer />
        </>
    );
}

export default FAQs;
