import React, { useEffect, useState, useRef } from "react";
import {useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import LoginModal from "../../Components/Modals/login_modal";
import VariationModal from "../../Components/Modals/variation_modal";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import constant from "../../Components/Services/constant";
import { ToastContainer, toast } from "react-toastify";
import numeral from "numeral";
import Productlistsidebar from "../../Components/Elements/product_list_sidebar";
import MobileHeader from "../../Components/Elements/mobile_header";
import QuickViewModal from "../../Components/Modals/quick_view_modal";
import sessionCartData from "../../Components/Elements/cart_session_data";
import StarRating from "../../Components/Elements/starrating";
import SortModal from "../../Components/Modals/sort_modal";
import FilterModal from "../../Components/Modals/filter_modal";
import Modal from "react-bootstrap/Modal";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import { Button } from "react-bootstrap";

function Collection({ onParentData }) {
   const navigate = useNavigate()
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [resProductsData, setProductsData] = useState([]);
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [AttributeData, setAttributeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState({});
  const [categoriesImageUrl, setCategoriesImageUrl] = useState("");
  const [loading, setLoading] = useState();
  const [sorting, setSorting] = useState("");
  const [CatName, setCatName] = useState("");
  const [catImage, setCatImage] = useState("");
  const { type, slug } = useParams();
  const [setSession, SetSession] = useState("");
  const [productData, setProductData] = useState(null);
  const [scroll, setScroll] = useState();
  const [helmetData, setHelmetData] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [serviceImagePath, setServiceImagePath] = useState("");
  const [showSort, setShowSort] = useState(false);
  const [categoriesName, setCategoriesName] = useState("");
  const [categoryImagePath, setCategoryImagePath] = useState("");

  const handleShowSortModal = () => {
    setShowSort(true);
  };
  const handleChildSortModalData = (status) => {
    setShowSort(status);
  };

  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilterModal = () => {
    setShowFilter(true);
  };
  const handleChildFilterModalData = (status) => {
    setShowFilter(status);
  };
  const [showVariation, setShowVariation] = useState(false);
  const handleShowVariation = (data) => {
    setProductData(data);
    setShowVariation(true);
  };
  const handleChildVariationModalData = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length);
    setShowVariation(status);
    // onParentData(status)
  };
  const [showQuick, setShowQuick] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleChildData = (status) => {
    setShow(status);
  };
  const handleShowQuickModal = (data) => {
    setProductData(data);
    setShowQuick(true);
  };
  const handleChildQuickModalData = (status) => {
    setShowQuick(status);
  };
  useEffect(() => {
    setLoading(true);
    if (didMountRef.current) {
      SetSession(sessionStorage.getItem("USER_TOKEN"));
      getProductList();
      getServiceslist();
      const dataString = {
        type: type,
        cat_slug: slug,
      };
      ApiService.postData("category-detail", dataString).then((res) => {
        if (res.status === "success") {
          setHelmetData(res.hemlet);
        }
      });
    }
    didMountRef.current = false;
  }, []);
  const productCarouselOptions = {
    loop: true,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 2.2,
      },
      600: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 4,
      },
    },
  };

  const getServiceslist = () => {
    const getServicesData = {
      slug: slug,
    };
    ApiService.postData("services-list", getServicesData).then((res) => {
      if (res.status == "success") {
        setServicesData(res.servicesData);
        setServiceImagePath(res.service_image_path);
        setCategoriesName(res.categoriesData);
        setCategoryImagePath(res.category_image_path);
      }
    });
  };

  const addtofav = (productId) => {
    const dataString = {
      product_id: productId,
    };
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status == "success") {
        var element = document.getElementById("wishlisticon" + productId);
        element.classList.remove("d-icon-heart", "d-icon-heart-full");
        element.classList.add(res.data.notification);
        if (res.data.notification === "d-icon-heart") {
          toast.success("Removed from wishlist");
        } else {
          toast.success("Added to Wishlist");
        }
      }
    });
  };

  const getProductList = () => {
    let pageNumber = Math.ceil(resProductsData.length / 12) + 1;
    const dataString = {
      type: type,
      slug: slug,
    };
    ApiService.fetchData(
      "product-list" +
        "?page=" +
        pageNumber +
        "&_limit=12&slug=" +
        slug +
        "&type=" +
        type
    ).then((res) => {
      if (res.status === "success") {
        setCategoriesData(res.categoriesData);
        setCatImage(res.categoriesData.cat_banner_image);
        setCategoriesImageUrl(res.category_img_url);
        setCatName(res.tagsData.tag_name);
        if (res.resProductsData.data.length > 0) {
          const apires = res.resProductsData.data;
          const mergeData = [...resProductsData, ...apires];
          setProductsData(mergeData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const handleFilteredData = (data) => {
    setProductsData(data);
  };

  const handleSortingClick = (sortingValue) => {
    setSorting(sortingValue);
  };

  const handlecatName = (data) => {
    setCatName(data);
  };
  const handlecatImage = (categoryData) => {
    setCatImage(categoryData);
  };
  const setscrolldata = (scroll) => {
    setScroll(scroll);
  };

  const handleClosesort = () => {
    setShowSort(false);
  };

  const handleClick = () =>{
      navigate('/contact-us')

  }
  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta
          name="description"
          itemprop="description"
          content={
            helmetData.description != null
              ? helmetData.description
              : "Lucian International"
          }
        />
        <meta
          name="keywords"
          content={
            helmetData.keywords ? helmetData.keywords : "Lucian International"
          }
        />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content={helmetData.title ? helmetData.title : "Lucian International"}
        />
        <meta property="og:image" content={helmetData.image} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={
            helmetData.description != null
              ? helmetData.description
              : "Lucian International"
          }
        />
        <meta name="twitter:url" content={window.location.href} />
        <meta
          name="twitter:title"
          content={helmetData.title ? helmetData.title : "Lucian International"}
        />
        <meta
          name="twitter:description"
          content={
            helmetData.description != null
              ? helmetData.description
              : "Lucian International"
          }
        />
        <meta property="twitter:image" content={helmetData.image} />
      </Helmet>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
      <BrowserView>
        <Header state="inner-header" />
        <div className="subheader">
          <Container>
            <Row>
              <Col lg={12}>
                <h1>{categoriesName.cat_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {categoriesName.cat_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

          <ul className="shape-group">
            <li className="shape-1">
              <span></span>
            </li>
            <li className="shape-2 scene">
              <img data-depth="2" src="/img/shape-13.png" alt="shape" />
            </li>
            <li className="shape-3 scene">
              <img data-depth="2" src="/img/shape-15.png" alt="shape" />
            </li>
            <li className="shape-4">
              <span></span>
            </li>
            <li className="shape-5 scene">
              <img data-depth="2" src="/img/shape-07.png" alt="shape" />
            </li>
          </ul>
        </div>
        <section className="sec-pad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                {categoriesName.cat_image != null ? (
                  <img
                  className="w-100"
                    src={
                      categoriesName.cat_image != null
                        ? categoryImagePath + "/" + categoriesName.cat_image
                        : ""
                    }
                    alt={categoriesName.cat_image}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6">
                <p>{categoriesName.cat_desc}</p>
                <Button
                    className="btn btn-primary btn-medium" onClick={handleClick}>
                      More Info
                    </Button>
          
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pad">
          <div className="container">
            <div className="row g-3">
            <div className="spacer1 mb-5"></div>
              {servicesData.map((value, index) => (
                <div className="col-lg-4">
                  <div className="servbox">
                    <div className="servbox-inner">
                      <div className="servboxthumbnail">
                        <a href={`/services/${value.services_slug}`}>
                          <img
                            src={
                              value.services_image != null
                                ? serviceImagePath + "/" + value.services_image
                                : constant.DEFAULT_IMAGE
                            }
                            alt={value.services_image}
                            style={{ width: "100%" }}
                          />
                        </a>
                      </div>
                      <div className="servboxcontent">
                        <h2>{value.services_name}</h2>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: value.services_short_description,
                          }}
                        ></p>
                        <div className="read-more-btn">
                          <a
                            className="btn btn-primary btn-medium"
                            href={`/services/${value.services_slug}`}
                          >
                            View Details <i className="icon-4"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      
        <Footer />
      </BrowserView>

      <MobileView>
      <Header state="inner-header" />
        <div className="subheader">
          <Container>
            <Row>
              <Col lg={12}>
                <h1>{categoriesName.cat_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {categoriesName.cat_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>

    
        </div>
      
         <section className="sec-pad">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-5">
                {categoriesName.cat_image != null ? (
                  <img
                  className="w-100"
                    src={
                      categoriesName.cat_image != null
                        ? categoryImagePath + "/" + categoriesName.cat_image
                        : ""
                    }
                    alt={categoriesName.cat_image}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6">
                <p>{categoriesName.cat_desc}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-pad">
          <div className="container">
            <div className="row g-3">
            <div className="spacer1 mb-5"></div>
              {servicesData.map((value, index) => (
                <div className="col-lg-4">
                  <div className="servbox">
                    <div className="servbox-inner">
                      <div className="servboxthumbnail">
                        <a href={`/services/${value.services_slug}`}>
                          <img
                            src={
                              value.services_image != null
                                ? serviceImagePath + "/" + value.services_image
                                : constant.DEFAULT_IMAGE
                            }
                            alt={value.services_image}
                            style={{ width: "100%" }}
                          />
                        </a>
                      </div>
                      <div className="servboxcontent">
                        <h2>{value.services_name}</h2>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: value.services_short_description,
                          }}
                        ></p>
                        <div className="read-more-btn">
                          <a
                            className="btn btn-primary btn-medium"
                            href={`/services/${value.services_slug}`}
                          >
                            View Details <i className="icon-4"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        <Footer />
      </MobileView>
      {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
      {showQuick && (
        <QuickViewModal
          showmodal={showQuick}
          productdata={productData}
          onChildData={handleChildQuickModalData}
        />
      )}
      {showSort && (
        <Modal
          show={showSort}
          onHide={handleClosesort}
          className="sortModal bottom"
        >
          <div className="sortModalbox">
            <button onClick={handleClosesort} className="pop-close">
              <i className="d-icon-times"></i>
            </button>
            <ul>
              <li
                onClick={(e) => {
                  handleSortingClick("featured");
                  setShowSort(false);
                }}
              >
                <span>Featured</span>
                <input
                  type="checkbox"
                  value="featured"
                  checked={sorting == "featured" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("bestselling");
                  setShowSort(false);
                }}
              >
                <span>Best selling</span>
                <input
                  type="checkbox"
                  value="bestselling"
                  checked={sorting == "bestselling" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("alphaasc");
                  setShowSort(false);
                }}
              >
                <span>Alphabetically, A-Z</span>
                <input
                  type="checkbox"
                  value="alphaasc"
                  checked={sorting == "alphaasc" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("alphadesc");
                  setShowSort(false);
                }}
              >
                <span>Alphabetically, Z-A</span>
                <input
                  type="checkbox"
                  value="alphadesc"
                  checked={sorting == "alphadesc" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("asc");
                  setShowSort(false);
                }}
              >
                <span>Price, low to high</span>
                <input
                  type="checkbox"
                  value="asc"
                  checked={sorting == "asc" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("desc");
                  setShowSort(false);
                }}
              >
                <span>Price, high to low</span>
                <input
                  type="checkbox"
                  value="desc"
                  checked={sorting == "desc" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("dateasc");
                  setShowSort(false);
                }}
              >
                <span>Date, old to new</span>
                <input
                  type="checkbox"
                  value="dateasc"
                  checked={sorting == "dateasc" ? true : false}
                />
              </li>
              <li
                onClick={(e) => {
                  handleSortingClick("datedesc");
                  setShowSort(false);
                }}
              >
                <span>Date, new to old</span>
                <input
                  type="checkbox"
                  value="datedesc"
                  checked={sorting == "datedesc" ? true : false}
                />
              </li>
            </ul>
          </div>
        </Modal>
      )}

      {showVariation && (
        <VariationModal
          showvariationmodal={showVariation}
          productdata={productData}
          onChildData={handleChildVariationModalData}
        />
      )}
    </>
  );
}
export default Collection;
